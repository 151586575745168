<template>
  <div class="subpage page-content">
    <div class="container">
      <div class="page-title">
        <h3>이용약관</h3>
      </div>
      <div class="content-wrap">
        <h4>&lt;제1장 총칙&gt;</h4>
        <h5>제1조(목적)</h5>
        <p>
          이 약관은 ㈜퓨처월드포(이하 "회사"라고 함)가 제공하는 게임 및 제반 서비스의 이용과 관련하여 회원과 회사 간의
          조건 및 절차에 관한 기본적인 사항을 정함을 목적으로 합니다.
        </p>

        <h5>제2조(용어의 정의)</h5>
        <ol>
          <li>
            ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <ol>
              <li>
                1. 이용 계약: 회사가 제공하는 서비스 이용과 관련하여 회사와 이용 고객 간에 체결하는 계약을 말합니다.
              </li>
              <li>
                2. 회원: 회사가 제공하는 절차에 따른 가입 신청 및 이용 계약 체결을 완료한 뒤, ID를 발급받아 회사의
                서비스를 이용할 수 있는 자를 말합니다.
              </li>
              <li>
                3. ID: 회원 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자, 특수문자, 숫자 등의 조합을
                말합니다.
              </li>
              <li>
                4. 체험 ID: 회원 식별과 회사가 제공하는 특정 서비스를 이용하기 위하여 회원이 선정하고 회사가 승인하는
                문자, 특수문자, 숫자 등을 말합니다.
              </li>
              <li>
                5. PASSWORD(이하 "비밀번호"라고 함): 회원이 자신의 ID 또는 체험 ID와 일치하는 이용 고객임을 확인하기
                위해 선정한 문자, 특수문자, 숫자 등의 조합을 말합니다.
              </li>
              <li>6. 회원 탈퇴: 회원이 이용 계약을 해지함을 의미합니다.</li>
              <li>7. 계정 : ID에 수반하여 게임 이용을 위해 회원이 별도로 생성하는 캐릭터, 게임 ID 등을 의미합니다.</li>
            </ol>
          </li>
          <li>
            ② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 각 서비스 별 안내에서
            정하는 바에 의합니다. 관계 법령과 각 서비스 별 안내에서 정하지 않는 것은 일반적인 상관례에 의합니다.
          </li>
        </ol>

        <h5>제3조(약관의 효력 및 적용과 변경)</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about'
}
</script>
